
import {defineComponent, ref} from "vue";
import 'flatpickr/dist/flatpickr.css';
import {libTools} from "@/core/appcore/libs/Lib_Tools";
import {dateConvert} from "@/core/filters/datatime";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import AppCore from "@/core/appcore/AppCore";
import { Field,} from "vee-validate";
import ManuelPosGarantiFormModal from "@/components/new-reservation/payment/ManuelPosGarantiFormModal.vue";


interface addItem {
  PaymentDate : string;
  PaymentAmount : string;
  PaymentType : string;
  OrderID : string;

}

interface ICardInfo {
  CardHolderName: string;
  CardNumber: string;
  CardExpiryMonth: string;
  CardExpiryYear: string;
  CardCvv: string;
}

interface IBankPosInfo {
  BankPosID: number;
  InstallmentCount: number;
}

export default defineComponent({
  name: "add-new-payment",
  components: {
    Field,
    ManuelPosGarantiFormModal,
  },





  setup() {

    const addItem = ref<addItem>({
      PaymentDate: "",
      PaymentAmount: "",
      PaymentType: "",
      OrderID: "",
    });


    var theBankPosInfo = ref<IBankPosInfo>({
      BankPosID: 0,
      InstallmentCount: 0,
    });
    var theCardInfo = ref<ICardInfo>({
      CardHolderName: "",
      CardNumber: "",
      CardExpiryMonth: "",
      CardExpiryYear: "",
      CardCvv: "",
    });
    let arrBankPoses = ref([]);
    let arrBankPosInstallments = ref([]);
    let selBankPos = ref({});
    let refComp_ModalPosGarantiForm = ref(null);
   /* function initComp() {
      console.log("PayInfo.vue initComp START");
      setCardInfoVisuals_By_BankPos();
      arrBankPoses.value = [];
      store.dispatch(Actions.BANKPOS_LIST, {}).then((prmData) => {
        if (prmData.data!==undefined) {
          console.log("BANKPOS_LIST: "+JSON.stringify(prmData));
          arrBankPoses.value = prmData.data;
        }
      });
    }*/

    store.dispatch(Actions.BANKPOS_LIST, {}).then((prmData) => {
      if (prmData.data!==undefined) {
        console.log("BANKPOS_LIST: "+JSON.stringify(prmData));
        arrBankPoses.value = prmData.data;
      }
    });

  /*  function setCardInfoVisuals_By_BankPos() {
      (document.getElementById("divPayInfo_CardInfo") as HTMLElement).style.display = "none";
      (document.getElementById("btnPayInfo_Payment_Garanti") as HTMLElement).style.display = "none";
      (document.getElementById("btnPayInfo_Payment_NomuPay") as HTMLElement).style.display = "none";


      if (selBankPos.value!==undefined && selBankPos.value!==null && (selBankPos.value as any).ID!==undefined) {
        //### Garanti
        if ((selBankPos.value as any).BankID+"" === "1") {
          (document.getElementById("divPayInfo_CardInfo") as HTMLElement).style.display = "";
          (document.getElementById("btnPayInfo_Payment_Garanti") as HTMLElement).style.display = "";
        }

        //### NomuPay
        if ((selBankPos.value as any).BankID+"" === "7") {
          (document.getElementById("btnPayInfo_Payment_NomuPay") as HTMLElement).style.display = "";
        }
      }
    }*/
    function click_ShowModalPosGaranti() {
      //AppCore.prsCore_Flight.theStepsBasket.AllTotal

      //console.log("click_ShowModalPosGaranti selBankPos: "+JSON.stringify(selBankPos.value));
      //let vvAmount = 1 * 100;
      let vvAmount = Number(addItem.value.PaymentAmount) * 100;
      if (theBankPosInfo.value.InstallmentCount+"" === "1") {
        theBankPosInfo.value.InstallmentCount = 0;
      }
      (refComp_ModalPosGarantiForm.value as any).showComp(theCardInfo.value, theBankPosInfo.value, vvAmount, "TRY", "", addItem.value.OrderID);
    }
    function change_Sel_BankPos(){

      arrBankPosInstallments.value = [];

      if (theBankPosInfo.value.BankPosID>0) {
        selBankPos.value = getBankPos_By_BankPosID(theBankPosInfo.value.BankPosID);
       //setCardInfoVisuals_By_BankPos();

        store.dispatch(Actions.BANKPOS_INSTALLMENT_WITH_COMMISSION_LIST, {BankPosID: theBankPosInfo.value.BankPosID, Type:"HOTEL", AllTotal:addItem.value.PaymentAmount}).then((prmData) => {
          if (prmData.data!==undefined) {
            arrBankPosInstallments.value = prmData;
            console.log("Manuel PAyment Page arrBankPosInstallments " + JSON.stringify(arrBankPosInstallments.value))
          }
        });
      }
    }
    function getBankPos_By_BankPosID(prmBankPosID){
      //console.log("getBankPos_By_BankPosID START prmBankPosID: "+prmBankPosID);
      let rv = null;
      if (arrBankPoses.value!==undefined && arrBankPoses.value!==null) {
        //console.log("getBankPos_By_BankPosID v1");
        for (let i=0; i<arrBankPoses.value.length; i++) {
          let each = arrBankPoses.value[i];
          //console.log("getBankPos_By_BankPosID each "+JSON.stringify(each));
          if (each.ID === prmBankPosID) {
            return each;
          }
        }
      }
      return rv;
    }

    function getObjIns(prmIns) {
      if (prmIns!==undefined) {
        return JSON.parse(prmIns);
      }
      return [];
    }

    function click_BankPosInsItem(event) {
      console.log("click_BankPosInsItem id: "+JSON.stringify(event.currentTarget.id)+" value: "+event.currentTarget.value);
      theBankPosInfo.value.InstallmentCount = event.currentTarget.value;
      const colBankInsItems = document.getElementsByClassName("payinfo-bankinsitem");
      for (let i = 0; i < colBankInsItems.length; i++) {
        const vvElement = colBankInsItems[i] as HTMLInputElement;
        const vvId = vvElement.getAttribute("id");
        if (event.currentTarget.id!==vvId) {
          vvElement.checked  = false;
        }
      }
      console.log("click_BankPosInsItem installmentCount" + event.currentTarget.value)

      store.dispatch(Actions.FLIGHT_RESERVATION_ADD_INSTALLMENT, {SearchCode:"UH212398459869POKTS", InstallmentCount:event.currentTarget.value, AllTotal: Number(addItem.value.PaymentAmount), Commission:event.currentTarget.getAttribute("commission")}).then((prmData) => {
        /*if (prmData.data!==undefined) {
          arrBankPosInstallments.value = prmData.data;
        }*/
      });
    }




/*

    function AddPayment() {

      const payloadAddPayment = {
        PaymentDate : dateConvert.method.flatpickerToDateYM(addItem.value.PaymentDate),
        Amount: addItem.value.PaymentAmount,
        Method: addItem.value.PaymentType,
        MethodID: "",
        AccountID:"1",
        DepartmentID:"1",
        Cur:"TRY",
        CurRate:"",
        Description:"",
        //Reservations: arrNewCheckedReservations.value,
      }

      console.log("AddCollection AddPayment payload" + JSON.stringify(payloadAddPayment));

      store.dispatch(Actions.PAYMENT_INSERT,  payloadAddPayment).then((prmData) => {
        libTools.method.swalSuccess("Tamam", "Tahsilat başarıyla eklendi");
      }).catch((prmData) => {
        libTools.method.swalError("Tamam", "Hata Oluştu");
      });


    }

*/


    return {
      libTools,
      addItem,
      //AddPayment,
      theBankPosInfo,
      arrBankPoses,
      //initComp,
      arrBankPosInstallments,
      change_Sel_BankPos,
      getObjIns,
      click_BankPosInsItem,
      theCardInfo,
      click_ShowModalPosGaranti,
      refComp_ModalPosGarantiForm,
    };
  },
});

